<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="WORK_IMPORTANT_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="workImportantCd"
            label="작업중요도"
            v-model="searchParam.workImportantCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="정비작업표준 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPop" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'work-standard-manage',
  data() {
    return {
      searchParam: {
        plantCd: null,
        workImportantCd: null,
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장명',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          // {
          //   name: 'swsNo',
          //   field: 'swsNo',
          //   label: '정비작업표준 관리번호',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width: 160px',
          // },
          {
            name: 'swsName',
            field: 'swsName',
            label: '정비작업표준명',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'workImportantName',
            field: 'workImportantName',
            label: '작업중요도',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'termTm',
            field: 'termTm',
            label: '작업예상시간',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'purpose',
            field: 'purpose',
            label: '목적',
            align: 'left',
            style: 'width: 300px',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.sws.mst.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '정비작업표준 상세';
      this.popupOptions.param = {
        mdmSwsId: row ? row.mdmSwsId : '',
        plantCd: row ? row.plantCd : '',
      };
      this.popupOptions.target = () => import(`${'./workStandardDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
